<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack m-0 p-0">
            Math with Max Carter.
          </h3>
          <p class="font-weight-bold text-colorGray mb-50">Code4545</p>
        </b-col>
        <b-col md="4" class="text-right pr-3 mt-50">
          <b-link :to="{ name: 'Lecture' }">
            <img src="@/assets/images/icons/lecture/cross.svg" img-fluid />
          </b-link>
        </b-col>
      </b-row>
      <b-row class="px-2">
        <b-col md="5">
          <div
            class="px-2 bg-colorWhite mb-3"
            style="border: 2px solid lightgray; border-radius: 20px"
          >
            <b-row class="py-2 d-flex align-items-center">
              <b-col md="5">
                <img
                  src="@/assets/images/icons/lecture/oxar.png"
                  width="160px"
                  height="150px"
                />
              </b-col>
              <b-col md="7" class="">
                <p class="font-weight-bold text-colorGray mb-50 p-0">
                  Data Scientist & ML Expert
                </p>
                <p class="font-weight-bold text-colorGray mb-50 p-0">
                  02 Classes
                </p>
                <p class="font-weight-bold text-colorGray mb-50 p-0">
                  700+ Students
                </p>
                <p class="font-weight-bold text-colorGray mb-50 p-0">
                  5 Star Ratings
                </p>
                <p class="font-weight-bold text-colorGray mb-50 p-0">
                  <img
                    src="@/assets/images/icons/lecture/star.svg"
                    img-fluid
                    class="mr-50"
                  />
                  <img
                    src="@/assets/images/icons/lecture/star.svg"
                    img-fluid
                    class="mr-50"
                  />
                  <img
                    src="@/assets/images/icons/lecture/star.svg"
                    img-fluid
                    class="mr-50"
                  />
                  <img
                    src="@/assets/images/icons/lecture/star.svg"
                    img-fluid
                    class="mr-50"
                  />
                  <img src="@/assets/images/icons/lecture/star.svg" img-fluid />
                </p>
              </b-col>
            </b-row>

            <p class="font-weight-bold text-colorGray">
              A Software technology evangelist with over 15+ years in the
              software Industry. A certification buff with numerous
              certifications with the most recent ones being AWS Solution
              Architect Associate , AWS Developer Associate , AWS SysOps
              Administrator Associate...
              <span class="font-weight-bolder text-colorBlue">
                <b-link> See more </b-link>
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
